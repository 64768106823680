@import "../../variables";
$base-width: 368px;

.button {
    margin: 0 auto;
    padding: 0.875rem 4rem 0.875rem 2rem;
    position: relative;
    background-color: $base-color;
    border: 1px solid $base-color;
    color: $white;
    transition: background-color 0.3s ease-in-out;
    border-radius: $radius;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 0.1em;
    min-width: 190px;
    text-align: left;

    &:hover {
        background-color: $white;
        color: $base-color;
        .long-arrow-right {
            transition: all 0.3s ease-in-out;
            right: -24px;
            border-color: $base-color;
            &::after {
                background-color: $base-color;
                // animation: moveArrowLeftColorHover 0.3s forwards ease-in;
            }
        }
    }

    &.second {
        background-color: $white;
        color: $base-color;

        &:hover {
            background-color: $base-color;
            color: $white;
        }
    }
    .long-arrow-right {
        margin: 30px auto;
        display: block;
        position: absolute;
        top: -10px;
        right: 18px;
        width: 12px;
        height: 12px;
        border-top: 1px solid $white;
        border-left: 1px solid $white;
        transform: rotate(135deg);
        transition: all 0.3s;

        &::after {
            content: "";
            display: block;
            width: 1px;
            height: 34px;
            background-color: $white;
            transform: rotate(-45deg) translate(12px, 5px);
            left: 0;
            top: 0;
            // animation: moveArrowLeftColor 0.3s forwards ease-in;
        }
    }

    @include media-breakpoint-down(md) {
        padding: 0.875rem 4rem 0.875rem 1.5rem;
        font-size: 0.875rem;
    }
}

@keyframes moveArrowLeft {
    0% {
        right: -24px;
        border-color: $base-color;
        &::after {
            background-color: $base-color;
        }
    }
    90% {
        border-color: $base-color;
        &::after {
            background-color: $base-color;
        }
    }
    100% {
        right: 18px;
        border-color: $white;
        &::after {
            background-color: $white;
        }
    }
}

@keyframes moveArrowLeftColor {
    0% {
        background-color: $base-color;
    }
    90% {
        background-color: $base-color;
    }
    100% {
        background-color: $white;
    }
}

@keyframes moveArrowLeftColorHover {
    0% {
        background-color: $white;
    }
    10% {
        background-color: $white;
    }
    100% {
        background-color: $base-color;
    }
}

@keyframes moveArrowRight {
    0% {
        right: 18px;
    }
    100% {
        right: -24px;
    }
}
