@import "../../variables";
.contacts-main {
    min-height: $main-height;
    background-image: url(../../assets/images/main-bg.jpg);
    background-size: cover;
    background-position: bottom;
    background-attachment: initial;

    @include media-breakpoint-down(xl) {
        min-height: $main-height - 50px;
    }

    .main-title-container {
        margin-top: 10rem;
        @include media-breakpoint-down(xl) {
            margin-top: 8rem;
        }
    }
}
.contacts-page-container {
    padding-bottom: 5rem;
    display: flex;

    background-image: url("../../assets/images/map-bg.jpg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    border-bottom: 1px solid #ddd;

    h2 {
        margin: 0;
        font-size: 3rem;
        @include media-breakpoint-down(md) {
            font-size: 2.25rem;
        }
    }
    span {
        margin-bottom: 2rem;
        display: block;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0.1em;
        @include media-breakpoint-down(md) {
            font-size: 0.75rem;
        }
    }
    p {
        margin: 2rem 0;
        // line-height: 2em;
        max-width: 700px;
        @include media-breakpoint-down(md) {
            font-size: 0.875rem;
        }
    }

    .button {
        margin: 3rem auto 2rem 0;
        span {
            display: none;
        }
    }

    .image-wrapper {
        width: 100%;
        &:nth-last-of-type(1) {
            padding-right: 2rem;
        }
        &:nth-last-of-type(3) {
            margin: 1.5rem 0 1rem 0;
            position: relative;
            right: 0.75rem;
            width: 170px;
        }
    }

    .contacts-context {
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // text-align: center;

        .avatar-container {
            margin-bottom: 1.5rem;
            display: flex;
            align-items: center;

            .image-wrapper {
                margin-right: 0.75rem;
                margin-bottom: 0.5rem;
                padding: 0;
                width: 110px;
                overflow: hidden;
                border-radius: 110px;
                border: 4px solid #f2f2f2f2;
            }
            span {
                margin: 0;
            }
        }

    }
}

.person-info-container {
    margin-left: 2.15rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    // align-items: center;

    .person-info-element {
        margin-bottom: 1rem;
        display: flex;
        // align-items: center;
        
        img {
            margin-right: 1rem;
            width: 30px;
            height: auto;
        }
        a {
            margin: 0;
            position: relative;
            top: 3px;
            color: $secondary-color;
            font-size: 1.125rem;
            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
        }
    }
}

.person-info-block {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    img {
        margin-right: 1rem;
        width: 30px;
        height: auto;
    }
    span {
        margin: 0;
        color: $secondary-color;
        font-size: 1.125rem;
    }

    &:nth-last-of-type(1) {
        margin-bottom: 2rem;
    }
}
