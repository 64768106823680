@import "../../variables";

.title-container {
  h2 {
    text-align: center;
    line-height: 1.6em;
    letter-spacing: 0.3em;
    color: $base-color;
    font-weight: 600;
    text-transform: uppercase;

    span {
      font-weight: 200;
    }
  }
  p {
    text-align: center;
    letter-spacing: 0.3em;
    color: $secondary-color;
  }
  img {
    margin: 1rem auto 1rem auto;
    display: block;
    width: 130px;
    height: auto;
  }

  @include media-breakpoint-down(sm) {
    transform: scale(0.8);
  }
}

