@import "../../variables";
.about-us-main {
    min-height: $main-height;
    background-image: url(../../assets/images/main-bg.jpg);
    background-size: cover;
    background-position: bottom;
    background-attachment:initial;
    
    @include media-breakpoint-down(xl) {
        min-height: $main-height - 50px;
    }

    .main-title-container {
        margin-top: 10rem;
        @include media-breakpoint-down(xl) {
            margin-top: 8rem;
          }
    }
}
.about-us-page-container {
    padding-bottom: 5rem;
    display: flex;
    text-align: center;
    
    h2 {
        margin-bottom: 1rem;
    }
    p {
        padding-bottom: 3rem;
    }
}