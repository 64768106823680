@import "../../variables";

.product-card-carousel {
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
  position: relative;
  transition: all 0.4s ease-in;

  &:hover {
    .ribbon {
      background-color: $base-color;
      color: $white;
    }
    a {
      opacity: 1;
      pointer-events: initial;
      transition: all 0.4s ease-in;
      border: 1px solid $base-color;
      .product-image {
        transform: scale(0.98);
      }
    }
  }

  .ribbon {
    padding: 0.5rem 1rem;
    position: absolute;
    top: 30px;
    left: 20px;
    background-color: $white;
    border: 1px solid $base-color;
    border-radius: $radius;
    color: $base-color;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    letter-spacing: 0.1em;
    cursor: default;
    transition: all 0.2s ease-in;
    z-index: 1;
  }

  .product-image {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    transition: all 0.2s ease-in;
  }

  a {
    margin-top: auto;
    text-decoration: none;
    opacity: 1;
    pointer-events: none;
    border: 1px solid transparent;

    .button {
      display: block;
    }
  }

  .product-card-info {
    padding: 0 0.75rem;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: $white;

    .product-title {
      margin: 0.5rem 0 auto 0;
      padding-bottom: 1rem;
      display: inline-block;
      font-size: 0.875rem;
      letter-spacing: 0.1em;
      font-weight: 700;

      span {
        font-weight: 400;
      }
    }

    .product-info {
      margin-bottom: 0.5rem;
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $base-color;

      .product-price {
        color: $base-color;
        h5 {
          display: none;
        }
        span {
          font-size: 1.25rem;
        }
      }

      p {
        color: $base-color;
        font-weight: 300;

        span {
          font-weight: 600;
          font-size: 1.25rem;
        }
      }
    }
  }
}
