@import "../../variables";

.main-container {
  display: flex;
  flex-direction: column;

  .main-title-container {
    overflow: hidden;
    z-index: 1;

    h1 {
      text-align: center;
      letter-spacing: 0.5em;
      color: $base-color;
      font-weight: 200;
      text-transform: uppercase;

      @include media-breakpoint-down(xl) {
        font-size: 2rem;
      }

      div {
        display: inline-flex;
        overflow: hidden;
        white-space: nowrap;

        &:first-of-type {
          opacity: 1;
        }

        &:last-of-type {
          width: auto;
          margin-left: 2rem;
          span {
            margin-left: 0;
          }
        }
      }

      span {
        font-weight: 600;
      }
    }
    p {
      font-size: 0.875rem;
      text-align: center;
      letter-spacing: 0.5em;
      color: $secondary-color;
      opacity: 1;

      @include media-breakpoint-down(xl) {
        font-size: 0.875rem;
      }
    }
  }

  img {
    margin: 0.5rem auto 1rem auto;
    display: block;
    width: 130px;
    height: auto;
  }

  &.estates {
    h1 {
      font-size: 1.75rem;
    }
    .button {
      display: none;
    }
  }

  &.services {
    .button {
      display: none;
    }
  }

  &.about-us {
    .button {
      display: none;
    }
  }

  &.contacts {
    h1 {
      div {
        &:first-of-type {
          margin-left: 0.5em;
        }
      }
    }
    .button {
      display: none;
    }
  }

  .button {
    margin-top: 5rem;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
}

.animate {
  .main-container {
    .main-title-container {
      h1 {
        text-transform: uppercase;

        div {
          &:first-of-type {
            animation: showup 7s forwards;
          }

          &:last-of-type {
            width: 0px;
            margin-left: 2rem;
            span {
              margin-left: -355px;
              animation: slidein 4s forwards;
            }
          }
        }

        span {
          font-weight: 600;
        }
      }
      p {
        animation-delay: 1.5s !important;
        opacity: 0;
        animation: showup 4s forwards;
      }
      img {
        animation-delay: 1.7s !important;
        opacity: 0;
        animation: showup 4s forwards;
      }
    }

    &.home {
      .main-title-container {
        div {
          &:last-of-type {
            animation: revealHome 4s forwards;
          }
        }
      }
    }
    &.estates {
      .main-title-container {
        div {
          &:last-of-type {
            animation: revealEstates 4s forwards;
          }
        }
      }
    }

    &.services {
      .main-title-container {
        div {
          &:last-of-type {
            animation: revealServices 4s forwards;
          }
        }
      }
    }

    &.about-us {
      .main-title-container {
        div {
          &:last-of-type {
            margin-left: 1rem;
            animation: revealAboutUs 4s forwards;
          }
        }
      }
    }

    &.contacts {
      .main-title-container {
        div {
          &:last-of-type {
            display: none;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .main-container {
      img {
        width: 105px;
      }
      .main-title-container {
        h1 {
          div:first-of-type {
            margin-bottom: 0.5rem;
          }
          :last-of-type {
            width: auto !important;
            margin-left: 0 !important;
        }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .main-container {
    .main-title-container {
      h1 {
        div:first-of-type {
          margin-bottom: 0.5rem;
        }
        :last-of-type {
          width: auto !important;
          margin-left: 0 !important;
      }
      }
    }
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

@keyframes fontScale {
  0% {
    font-size: 2.5rem;
  }
  100% {
    font-size: 2rem;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slidein {
  0% {
    margin-left: -600px;
  }
  20% {
    margin-left: -600px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes revealHome {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 160px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    width: 160px;
  }
}

@keyframes revealEstates {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: auto;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    width: auto;
  }
}

@keyframes revealServices {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 640px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    width: 640px;
  }
}

@keyframes revealAboutUs {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 110px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    width: 110px;
  }
}
