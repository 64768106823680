@import "../../variables";

.counter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Oswald", sans-serif;
    color: $base-color;

    .counter-value {
        font-size: 2rem;
        font-weight: 800;
        &::after {
            content: "+";
            font-size: 2rem;
        }
    }

    .counter-title {
        font-size: 1.5rem;
        font-weight: 100;
    }

    @include media-breakpoint-down(sm) {
        .counter-title {
            font-size: 1rem;
            font-weight: 100;
        }
    }
}
