@import "../../variables";

.latest-product-card {
  margin-bottom: 2rem;
  // background-color: $white;
  position: relative;

  h2 {
    font-size: 2rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }

  }

  .title-container {
    margin-bottom: 4rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }

  .product-image-link {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    // max-height: 540px;
    transition: all 0.2s ease-in;

    &:hover {
      .ribbon {
        background-color: $base-color;
        color: $white;
      }
    }
    .ribbon {
      padding: 0.5rem 1rem;
      position: absolute;
      top: -18px;
      left: 26px;
      background-color: $white;
      border: 1px solid $base-color;
      border-radius: $radius;
      color: $base-color;
      font-family: "Oswald", sans-serif;
      font-weight: 400;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      cursor: default;
      transition: all 0.2s ease-in;
      z-index: 2;
    }

    .product-id {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -6rem;
      bottom: -1.5rem;
      background-color: $white;
      color: $base-color;
      border: 1px solid #ddd;
      min-width: 4rem;
      max-height: 3.15rem;
      border-radius: $radius;
      p {
        font-weight: 200;
        text-transform: uppercase;
        font-size: 1rem;

        font-family: "Oswald", sans-serif;
        span {
          font-size: 1.5rem;
          font-weight: 500;
        }
      }
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .product-image {
      overflow: hidden;
      border: 1px solid transparent;
      transition: all 0.2s ease-in;

      img {
        display: block;
        width: 100%;
        height: 450px;
        max-height: 450px;
        object-fit: cover;
        object-position: center;
        transition: all 0.2s ease-in;
      }
      &:hover {
        border: 1px solid $base-color;
        img {
          transform: scale(0.97);
        }
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;

        img {
          height: 260px;
          max-height: 260px;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;

        img {
          height: 260px;
          max-height: 260px;
        }
      }
    }
  }
}

.product-info-container {
  padding-left: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @include media-breakpoint-down(md) {
    padding: 0;
  }
  // background-color: $white;

  .product-info-title {
    margin-bottom: 1rem;
    .product-title {
      margin: 0 0 0.5rem 0;
      display: inline-block;
      font-size: 1.5rem;
      letter-spacing: 0.1em;
      font-weight: 800;

      span {
        font-weight: 400;
      }
    }
    p {
      padding-top: 1rem;
      display: -webkit-box;
      line-height: 1.6em;
      border-top: 1px solid $base-color;
      height: 150px;
      max-height: 115px;
      overflow: hidden;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .button {
      margin-bottom: 1rem;
    }
    h5 {
      margin-bottom: 0.5rem;
      font-size: 1.125rem;
      letter-spacing: 0.1em;
    }

    .product-price-wrapper {
      margin-bottom: 3rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .product-price {
        color: $base-color;
        h5 {
          display: none;
        }
        span {
          font-size: 1.5rem;
        }
      }

      p {
        color: $base-color;
        font-weight: 300;

        span {
          font-weight: 600;
          font-size: 1.25rem;
        }
      }
    }
    @include media-breakpoint-down(md) {
      margin-top: 1rem;
    }
  }
}
