@import "../../variables";

.social-icon {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    img {
        margin-right: 1rem;
        width: auto;
        height: 25px;
        object-fit: contain;
        object-position: left;
        opacity: 1;
        transition: 0.2s ease-in;
        &:hover {
            opacity: 1;
        }
    }

    p {
        margin: 0 !important;
        display: inline-flex;
        color: $base-color;
        transition: 0.2s ease-in;
        font-size: 0.875rem;
        @include media-breakpoint-down(sm) {
            font-size: 0.75rem;
        }

        span {
            margin: 0 0 0 0.5rem;
            color: $base-color;
            font-size: 1rem;
            font-weight: 900;

            @include media-breakpoint-down(sm) {
                font-size: 0.875rem;
            }
        }
        &:hover {
            color: $secondary-color;
        }
    }
}
