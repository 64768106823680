@import "../../variables";

.dropdown {
    .dropdown-select {
        .dropdown-select__control {
            margin-bottom: 1rem;
            background-color: $white;
            color: $dark;
            font-weight: 600;
            border-color: $base-color;
            border-radius: $radius;
            border: 1px solid $base-color;
            transition: all 0.2s ease-in;

            .dropdown-select__value-container {
                padding: 0.75rem;
            }

            &:hover {
                border: 1px solid $base-color;
                color: $dark;
                background-color: $white;
                box-shadow: 0 0 12px rgba($color: $base-color, $alpha: 0.3);
            }
        }
    }
    
    .dropdown-toggle {
        margin-bottom: 1rem;
        width: 100%;
        background-color: $white;
        color: $dark;
        font-weight: 600;
        border-color: $base-color;
        border-radius: $radius;
        border: 1px solid $base-color;

        &::after {
            $arrow-size: 10px;
            display: inline-block;
            position: relative;
            top: 0;
            right: 2rem;
            content: "";
            width: $arrow-size;
            height: $arrow-size;
            border: 2px solid $base-color;
            border-left: 0;
            border-bottom: 0;
            transform: rotate(45deg) translateY(2px);
            animation: rotateArrowBack 0.2s forwards;
        }

        &.selected {
            &::after {
                $arrow-size: 10px;
                display: inline-block;
                position: relative;
                top: 0;
                right: 2rem;
                content: "";
                width: $arrow-size;
                height: $arrow-size;
                border: 2px solid $base-color;
                border-left: 0;
                border-bottom: 0;
                transform: rotate(135deg) translateY(0px);
                animation: rotateArrowBack 0.2s forwards;
            }
        }

        @keyframes rotateArrowBack {
            from {
                transform: rotate(135deg) translateY(0px);
            }
            to {
                transform: rotate(45deg) translateY(2px);
            }
        }

        &:active {
            border: 1px solid $base-color;
            color: $dark;
            background-color: $white;
            box-shadow: 0 0 12px rgba($color: $base-color, $alpha: 0.2);
        }
        &:hover {
            border: 1px solid $base-color;
            color: $dark;
            background-color: $white;
            box-shadow: 0 0 12px rgba($color: $base-color, $alpha: 0.3);
        }
        &:focus {
            border: 1px solid $base-color;
            color: $dark;
            background-color: $white;
            box-shadow: 0 0 12px rgba($color: $base-color, $alpha: 0.3);
        }

        select::-ms-expand {
            display: none;
        }

        select {
            padding: 0.875rem 1rem;
            width: 100%;
            border: none;
            border-radius: $radius;
            font-weight: 600;
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &:focus-visible {
                border: none;
                outline: none;
            }

            option {
                padding: 30px;
                line-height: 3em;
                cursor: pointer;

                &:hover {
                    background: $base-color;
                }
            }
        }
    }
    .dropdown-menu {
        margin: 8px 0 !important;
        width: 100%;
        box-shadow: 0 12px 24px 0 rgba($color: #070448, $alpha: 0.2);
    }
}
