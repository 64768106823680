@import "../../variables";

.dashboard-images-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    .dashboard-bg-image {
        position: absolute;
        right: -20%;
        width: 600px;
        height: 600px;
        z-index: -1;

        @include media-breakpoint-down(xl) {
            visibility: hidden;
            opacity: 0;
            display: none;
            transition: 0.3s;
        }
    }
}

.dashboard-image-container {
    position: absolute;
    border: 0.5rem solid $white;
    box-shadow: 0 5px 25px 0 rgba($color: $base-color, $alpha: 0.15);
    border-radius: $radius * 2;
    transition: 0.3s;
    z-index: 0;

    &::after {
        padding: 0.5rem 1rem;
        position: absolute;
        top: 1rem;
        color: $base-color;
        background-color: $white;
        font-family: "Oswald", sans-serif;
        font-weight: 100;
        border-radius: $radius;
        border: 1px solid $base-color;
        transition: 0.3s;
    }

    &.first {
        height: 190px;
        width: 190px;
        top: 0%;
        right: -25%;
        &::after {
            content: "НАЕМИ";
            left: 72%;
        }
    }

    &.second {
        height: 170px;
        width: 170px;
        top: 29%;
        right: 14%;

        &::after {
            content: "КУПИ";
            left: 72%;
        }
    }

    &.third {
        height: 230px;
        width: 230px;
        right: -12%;
        bottom: 0%;

        &::after {
            content: "ПРОДАЙ";
            left: 74%;
        }
    }

    .dashboard-image-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        .image-wrapper {
            position: absolute;
            background-color: $white;
            width: 100%;
            height: 100%;
            border-radius: ($radius * 2) - 8px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: 0.3s;
            }
        }
    }

    &:hover {
        transition: 0.3s;
        cursor: none;
        &.first {
            right: -27%;
        }

        &.second {
            right: 15%;
        }

        &.third {
            right: -14%;
        }
        &::after {
            left: 90%;
            top: 1rem;
            color: $white;
            background-color: $base-color;
        }
        .dashboard-image-wrapper {
            .image-wrapper {
                img {
                    transition: 0.3s;
                }
            }
        }
    }

    @include media-breakpoint-down(xl) {
        visibility: hidden;
        opacity: 0;
        display: none;
        transition: 0.3s;
    }
}
