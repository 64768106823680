@import "../../variables";
.image-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    img {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
        object-fit: cover;
        user-select: none;
    }
}