@import "../../../variables";

.contact-form {
    position: sticky;
    top: 6.5rem;
    padding: 2rem;
    background-color: $bg-color-first;
    border-radius: $radius;
    transition: all 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 30px 25px -20px rgba($color: $base-color, $alpha: 0.3);
    }

    
    @include media-breakpoint-down(md) {
        padding: 1.5rem 1rem;
    }

.form-label {
    font-family: "Oswald", sans-serif;
}
    .form-control {
        margin-bottom: 1rem;
        padding: 0.75rem;
    }
    h3 {
        margin-bottom: 1rem;
        font-size: 2rem;
        @include media-breakpoint-down(md) {
            font-size: 1.875rem;
        }
    }
    .button {
        margin-top: 1rem;
        margin-bottom: 0;
    }
}
