@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

$base-color: #1f4280;
$secondary-color: #a22a68;
$sold-color: #adbed2;

$research: #a22a68;

$white: #fff;
$dark: #2e2e2e;

$bg-color-first: rgba(242, 242, 242, 0.8);
$border-color: #dbe1eb;

$main-height: 350px;

$radius: 8px;

// --- Media --- //
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$small-xl: 1183;
$small-xxl: 1382;
$xl: 1200px;
$small-xl: 1183px;
$small-xxl: 1382px;
$xxl: 1400px;


@mixin media-breakpoint-down($size) {
    @if $size==sm {
        @media (max-width: ($md - 0.02)) {
            @content;
        }
    } @else if $size==md {
        @media (max-width: ($lg - 0.02)) {
            @content;
        }
    } @else if $size==lg {
        @media (max-width: ($xl - 0.02)) {
            @content;
        }
    } @else if $size==xl {
        @media (max-width: ($xxl - 0.02)) {
            @content;
        }
    } @else if $size==xxl {
        @media (max-width: ($xxl - 0.02)) {
            @content;
        }
    }
}

@mixin media-breakpoint-up($size) {
    @if $size==sm {
        @media (min-width: $sm) {
            @content;
        }
    } @else if $size==md {
        @media (min-width: $md) {
            @content;
        }
    } @else if $size==lg {
        @media (min-width: $lg) {
            @content;
        }
    } @else if $size==xl {
        @media (min-width: $xl) {
            @content;
        }
    } @else if $size==xxl {
        @media (min-width: $xxl) {
            @content;
        }
    }
}

@mixin media-breakpoint-only($size) {
    @if $size==xs {
        @media (min-width: $xs) and (max-width: ($sm - 0.02)) {
            @content;
        }
    } @else if $size==sm {
        @media (min-width: $sm) and (max-width: ($md - 0.02)) {
            @content;
        }
    } @else if $size==md {
        @media (min-width: $md) and (max-width: ($lg - 0.02)) {
            @content;
        }
    } @else if $size==lg {
        @media (min-width: $lg) and (max-width: ($xl - 0.02)) {
            @content;
        }
    } @else if $size==xl {
        @media (min-width: $xl) and (max-width: ($xxl - 0.02)) {
            @content;
        }
    } @else if $size==small-xl {
        @media (min-width: $small-xl) and (max-width: ($small-xxl - 0.02)) {
            @content;
        }
    } @else if $size==xxl {
        @media (min-width: $xxl) {
            @content;
        }
    }
}

@include media-breakpoint-only(xs) {
    .row > * {
        padding-right: calc(var(--bs-gutter-x) * 0.65);
        padding-left: calc(var(--bs-gutter-x) * 0.65);
    }
}


// ===== Animations =====

@keyframes elementShow {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes buttonShow {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes firstLoad {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
