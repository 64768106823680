@import "../../variables";
.product-list-main {
    min-height: $main-height - 80px;
    background-image: url(../../assets/images/main-bg.jpg);
    background-size: auto;
    background-position: top;
    background-attachment: initial;
    background-repeat: no-repeat;

    .main-title-container {
        margin-top: 10rem;
        @include media-breakpoint-down(xl) {
            margin-top: 8rem;
        }
    }
}

.product-list-page-container {
    margin-top: 1rem;
    padding-bottom: 5rem;
    display: flex;
    min-height: 830px;
    background-image: url(../../assets/images/bottom-bg.jpg);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: initial;
    border-bottom: 1px solid #ddd;
    h2 {
        margin-top: 3rem;
        padding-bottom: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 1px solid $base-color;
        span {
            font-weight: 200;
        }
    }
}

.go-to-search {
    .button {
        margin: 5rem auto 1rem auto;
        display: flex;
        width: auto;
    }
}
.tabs-container {
    padding: 3rem 0 0 0;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        padding: 1rem 0;
    }

    .tabs-wrapper {
        padding: 0;

        .accent-container {
            &.tab {
                margin: 0 auto;
                padding: 2rem !important;
                background-color: $bg-color-first;
                max-width: 360px !important;
                min-height: 335px;
                border-radius: $radius + 5px;
                position: relative;
                box-shadow: 0 19px 25px -20px rgba($color: $base-color, $alpha: 0.3);
                transition: all 0.3s ease-in-out;

                &:hover {
                    box-shadow: 0 30px 25px -20px rgba($color: $base-color, $alpha: 0.3);
                }

                &.sale {
                    margin-bottom: 10rem;
                }

                @include media-breakpoint-down(sm) {
                    margin: 0 auto 0 auto !important;
                    padding: 2rem 1.25rem !important;
                    max-width: 320px !important;

                    .button-wrapper {
                        .button {
                            min-width: 170px;
                        }
                    }
                }
                .button {
                    &.no-arrow {                        
                        padding: 0.875rem 0;
                        .long-arrow-right {
                            display: none;
                        }
                    }
                }
            }
        }

        .tabs {
            margin-bottom: 1.5rem;
            display: flex;
            justify-content: center;
            position: relative;

            .nav-item {
                font-family: "Oswald", sans-serif;
                color: $base-color;
                text-transform: uppercase;
                font-size: 1.25rem;
                letter-spacing: 0.1em;
                cursor: pointer;

                &:hover {
                    color: $base-color;
                }
            }
        }

        .nav-pills {
            border: none;
            .nav-link {
                margin: 0 0.5rem;
                padding: 0;
                color: $base-color;
                border-bottom: 3px solid transparent;
                opacity: 0.8;
                transition: all 0.2s ease-in;
                border-radius: 0;

                &.active {
                    opacity: 1;
                    color: $base-color;
                    background-color: transparent;
                    border-bottom: 3px solid $base-color;

                    &:hover {
                        color: $base-color;
                        border-bottom: 3px solid $base-color;
                    }
                }
                &:hover {
                    opacity: 1;
                    color: $secondary-color;
                    border-bottom: 3px solid transparent;
                }
                &:focus {
                    border-color: transparent;
                    border-bottom: 3px solid transparent;
                }
            }
            .nav-item {
                &.show {
                    .nav-link {
                        color: $base-color;
                    }
                }
            }
        }
        .content {
            text-align: center;
            h2 {
                text-align: left;
            }
            .button {
                letter-spacing: 0.1em;

                &.second {
                    margin-bottom: 0;
                }
            }
            .button-wrapper {
                > .button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .specific {
                        margin: 0;
                        margin-right: 0.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $white;
                        color: $base-color;
                        min-width: 25px;
                        max-height: 25px;
                        width: auto;
                        height: 100%;
                        padding: 5px;
                        font-size: 0.875rem;
                        border-radius: 30px;
                        font-family: "Oswald", sans-serif;
                    }
                    &:hover {
                        .specific {
                            background-color: $base-color;
                            color: $white;
                        }
                    }
                }
            }

            .accent-container {
                .image-wrapper {
                    margin: 2rem auto 1rem auto;
                    width: 45px;
                }
                .button-wrapper {
                    position: relative;
                    .clear-wrapper {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0.5rem;
                        .button {
                            &.clear {
                                margin: 0;
                                padding: 5px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 35px;
                                max-width: 35px;
                                min-width: auto;
                                height: 35px;
                                max-height: 35px;
                                border-radius: 30px;
                                border-color: $base-color;
                                background-color: $white;

                                &:hover {
                                    box-shadow: 0 0 12px 0 rgba($color: $base-color, $alpha: 0.5);
                                }

                                &.active {
                                    animation: rotateIn 0.2s ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }

        .btn-primary:not(:disabled):not(.disabled):active:focus,
        .btn-primary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-primary.dropdown-toggle:focus {
            color: $dark;
            background-color: $white;
            border-color: $base-color;
            box-shadow: none;
        }

        .dropdown-menu {
            position: absolute;
            z-index: 1000;
            display: none;
            min-width: 10rem;
            padding: 0.5rem 0;
            margin: 0;
            font-size: 1rem;
            color: #212529;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid $base-color;
            border-radius: 0.25rem;

            .dropdown-item {
                &:active {
                    background-color: $base-color;
                }
            }
        }
    }
}

.product-list {
    padding-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 15px;
    row-gap: 15px;
    position: relative;

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }
}

@keyframes rotateIn {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes rotateOut {
    0% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(0);
    }
}

.no-products {
    margin-top: 1rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    text-align: center;
    background-color: $white;
    border-radius: $radius;

    .search-icon {
        margin-right: 0.75rem;
        width: 35px;
        height: 35px;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}
