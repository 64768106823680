@import "../../variables";

.product-card {
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 5px;
  display: flex;
  flex-direction: column;
  max-width: 340px;
  width: 100%;
  background-color: $white;
  position: relative;
  border: 1px solid transparent;
  transition: border 0.3s ease-in;

  &:hover {
    border: 1px solid $base-color;
    box-shadow: 0 30px 25px -20px rgba($color: $base-color, $alpha: 0.3);
    .product-image {
      padding: 2px;
    }
    .ribbon {
      background-color: $base-color;
      color: $white;
    }
  }

  &.sold {
    cursor: default;
    opacity: 0.35;
    transition: opacity 0.5s;
    &:hover {
      opacity: 1;
      border-color: $sold-color;

    }
    
    .ribbon {
      background-color: $base-color;
      color: $white;
      pointer-events: none;
      top: 33%;
      left: 50%;
      transform: translate(-50%, -33%);

    }
    
    .product-image,
    .product-card-info {
      pointer-events: none;
    }
  }

  .ribbon {
    padding: 0.5rem 1rem;
    position: absolute;
    top: -18px;
    left: 20px;
    background-color: $white;
    border: 1px solid $base-color;
    border-radius: $radius;
    color: $base-color;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    letter-spacing: 0.1em;
    cursor: default;
    transition: all 0.2s ease-in;
    z-index: 1;
  }

  .product-image {
    position: relative;
    width: 100%;
    height: 230px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    transition: all 0.2s ease-in;

    @include media-breakpoint-down(lg) {
      height:165px;
    }

    @include media-breakpoint-down(sm) {
      height: 260px;
    }
  }

  .product-card-info {
    padding: 0 0.75rem;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: $white;

    .product-title {
      margin: 0.75rem 0 auto 0;
      padding-bottom: 0.75rem;
      display: inline-block;
      font-size: 0.875rem;
      text-align: left;
      letter-spacing: 0.1em;
      font-weight: 700;

      span {
        font-weight: 400;
      }
    }

    .product-info {
      margin-bottom: 0.5rem;
      padding-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $base-color;

      h4 {
        margin-bottom: 1rem;
      }

      .product-price {
        color: $base-color;
        h5 {
          display: none;
        }
        span {
          font-size: 1.125rem;
        }
      }

      p {
        color: $base-color;
        font-weight: 300;

        span {
          font-weight: 600;
          font-size: 1.125rem;
        }
      }
    }
  }
}
