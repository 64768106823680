@import "../../variables";

.services-preview-container {
  padding: 6rem 12rem !important;
  display: flex;
  flex-direction: column;
  background-color: $bg-color-first;

  @include media-breakpoint-down(xl) {
    padding: 6rem !important;
  }

  @include media-breakpoint-down(lg) {
    padding: 6em !important;
  }

  @include media-breakpoint-down(md) {
    padding: 4em !important;
  }

  @include media-breakpoint-down(sm) {
    padding: 2em !important;
  }

  .preview-title-container {
    margin-bottom: 6rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
    }
  }
  h3 {
    text-align: center;
    letter-spacing: 0.5em;
    color: $base-color;
    font-weight: 200;
    text-transform: uppercase;
    line-height: 1.6em;

    span {
      font-weight: 600;
    }

    @include media-breakpoint-down(xl) {
      font-size: 1.25rem;
    }
  }
  
  p {
    text-align: center;
    letter-spacing: 0.5em;
    color: $secondary-color;
    @include media-breakpoint-down(xl) {
      font-size: 0.75rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 0.65rem;
    }
  }

  .service-block {
    margin-bottom: 1rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image-wrapper {
      margin: 0 auto 1.5rem auto;
      width: 65px;
    }

    h5 {
      margin-bottom: 1rem;
      font-size: 1rem;
      line-height: 1.8em;
      text-align: center;
      letter-spacing: 0.1em;
      color: $base-color;
      font-weight: 300;
      text-transform: uppercase;

      span {
        font-weight: 600;
      }
    }
    p {
      margin-bottom: 1rem;
      font-size: 0.875rem;
      text-align: center;
      letter-spacing: 0.2em;
      line-height: 1.4em;
      color: $dark;
      display: -webkit-box;
      height: 55px;
      max-height: 65px;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  a {
    margin: 0 auto;

    .button {
      margin-top: 5rem;
      text-align: center;
      letter-spacing: 0.1em;
      font-weight: 500;
    }
  }
}
