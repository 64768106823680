@import "../../variables";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.product-page {
    margin: 0 auto 3rem auto;
    padding-top: 8rem;

    .product-page-title-container {
        margin-bottom: 0.5rem;
        padding-bottom: 1rem;
        position: relative;

        h1 {
            margin: 0;
        }

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: calc(100% - 20px);
            height: 1px;
            background-color: $base-color;
        }

        .product-page-title {
            span {
                font-weight: 200;
            }
        }

        .price {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }

    .product-short-description {
        margin-bottom: 2rem;

        p {
            span {
                font-weight: 800;
            }
        }

        .product-params {
            padding-left: 2rem;
            display: grid;
            grid-template-columns: 1.3fr 0.7fr;

            p {
                &:nth-child(even) {
                    text-align: end;
                }
            }
        }
    }

    .current-product-container {
        margin-bottom: 6rem;
        position: relative;

        .image-gallery {
            position: sticky;
            top: 90px;
        }

        .social-icon {
            margin-bottom: 0.5rem;
            display: flex;
            color: $dark;

            img {
                margin-left: 0;
                margin-right: 0.5rem;
            }

            &:first-of-type {
                img {
                    width: 80px;
                }
            }

            &:last-of-type {
                img {
                    width: 30px;
                }
            }
        }

        .products-gallery {
            margin-bottom: 1rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 150px 150px;
            column-gap: 15px;
            row-gap: 15px;
        }

        .ribbon {
            padding: 0.5rem 1rem;
            position: absolute;
            top: -18px;
            left: 26px;
            background-color: $base-color;
            border: 1px solid $base-color;
            border-radius: $radius;
            color: $white;
            font-family: "Oswald", sans-serif;
            font-weight: 400;
            letter-spacing: 0.1em;
            cursor: default;
            transition: all 0.2s ease-in;
            z-index: 1;
        }

        .product-info-container {
            .product-info {
                h4 {
                    font-size: 1.25rem;
                    text-transform: uppercase;
                    margin-bottom: 0.5rem;

                    span {
                        font-size: 1.25rem;
                        font-weight: 300;
                    }
                }
            }

            .view-container {
                margin: 2rem 0 1rem 0;
                display: flex;
                align-items: center;

                p {
                    margin: 0 0 0 1rem;
                    padding-left: 30px;
                    position: relative;
                    color: $base-color;
                    font-weight: 600;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        background-image: url(../../assets/icons/phone.svg);
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 20px;
                    }
                }

                .button {
                    margin: 0;
                }

                &:hover {
                    p {
                        margin: 0 0 0 2.5rem;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 6rem;
        }
    }

    .react-multi-carousel-list {
        margin-top: 3rem;

        .react-multi-carousel-track {
            margin-bottom: 2rem;
        }

        .react-multiple-carousel__arrow {
            top: 20%;
            background-color: transparent !important;
            opacity: 0.9;

            &::before {
                font-size: 4rem !important;
                color: $white !important;
                font-weight: 500;
                text-shadow: 3px 1px 3px $dark;
                transition: all 0.2s ease-in;
            }

            &:hover {
                opacity: 1;
                transition: all 0.2s ease-in;

                &::before {
                    color: #337ab7 !important;
                }
            }
        }

        .react-multiple-carousel__arrow--left {
            left: calc(2% + 1px);
        }

        .react-multiple-carousel__arrow--right {
            right: calc(2% + 1px);
        }
    }

    .product-image-wrapper,
    .product-map {
        margin: 0.5rem 0;
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: $radius;
        box-shadow: 0 0 0 3px #f2f2f2;
        transition: all 0.2s ease-in-out;
        overflow: hidden;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &:hover {
            box-shadow: 0 0 0 3px #dbe1eb;
        }
    }

    .product-map {
        height: 130px;
    }

    .property-info-container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .product-info {
            margin: 1.25rem 0 1rem 0;
        }
    }

    @include media-breakpoint-down(lg) {
        padding-top: 5rem;

        .product-page-title-container {
            h1 {
                font-size: 2rem;
                line-height: 1.4em;
            }

            .price {
                .product-price {
                    h5 {
                        font-size: 1.25rem;
                    }

                    p {
                        span {
                            font-size: 1.65rem;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .product-page-title-container {
            h1 {
                font-size: 1.75rem;
            }

            .price {
                .product-price {
                    h5 {
                        font-size: 1.25rem;
                    }

                    p {
                        span {
                            font-size: 1.65rem;
                        }
                    }
                }
            }
        }

        .current-product-container {
            .product-info-container {
                margin-top: 8rem;
                padding-left: 0;
                justify-content: center;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 5rem;

        .product-page-title-container {
            h1 {
                font-size: 1.5rem;
            }

            .price {
                margin-top: 1.5rem;
                justify-content: flex-start;

                .product-price {
                    h5 {
                        font-size: 1.25rem;
                    }

                    p {
                        span {
                            font-size: 1.65rem;
                        }
                    }
                }
            }
        }

        .product-short-description {
            .product-params {
                padding-left: 12px;
                display: flex;
                flex-direction: column;

                p:nth-child(even) {
                    text-align: start;
                }
            }
        }

        .current-product-container {
            margin-bottom: 2rem;

            .product-info-container {
                margin-top: 0;
                padding-left: 0;

                .view-container {
                    flex-direction: column;
                    align-items: flex-start;

                    .button {
                        margin-bottom: 1rem;
                    }

                    p {
                        margin: 0;
                    }
                }
            }
        }

        .react-multi-carousel-list {
            margin-top: 0;
        }
    }
}

.image-gallery-content {
    margin-bottom: 2rem;

    &::after {
        content: "";
        position: absolute;
        border-radius: $radius;
        background: linear-gradient(0deg, #1f428029 0%, #a529660a 70%);
        width: calc(100% + 2rem);
        height: calc(100% - 110px);
        top: 35px;
        left: -1rem;
        z-index: 0;

        @include media-breakpoint-down(sm) {
            content: none;
        }
    }

    &::before {
        content: "";
        position: absolute;
        width: calc(100% + 2rem);
        height: calc(100% - 110px);
        top: 35px;
        left: -1rem;
        border-radius: $radius;
        z-index: -1;
        background-image: url(../../assets/images/1212.svg);
        background-size: 60%;
        background-repeat: no-repeat;
    }

    .image-gallery-slide-wrapper {
        $height-image: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: $height-image;
        max-height: $height-image;
        background-color: $bg-color-first;
        overflow: hidden;

        .image-gallery-swipe {
            padding: 5px;
            background-color: $white;
            border: 3px solid $border-color;
            width: 100%;
            height: 100%;
            transition: border 0.3s ease-in-out;
            z-index: 1;

            .image-gallery-slides {
                height: 100%;

                .image-gallery-slide {
                    height: 100%;

                    .image-gallery-image {
                        height: 100%;
                        max-height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .image-gallery-slides {
            height: 100%;

            .image-gallery-slide {
                height: 100%;

                .image-gallery-image {
                    height: 100%;
                    max-height: 100%;
                    object-fit: cover;
                }
            }
        }

        @include media-breakpoint-down(lg) {
            height: 335px;
            max-height: 335px;
        }

        @include media-breakpoint-down(md) {
            height: 455px;
            max-height: 455px;
        }

        @include media-breakpoint-down(sm) {
            height: 250px;
            max-height: 250px;
        }
    }

    &.fullscreen {
        .image-gallery-slide-wrapper {
            height: 100%;
            max-height: 100%;
            background: #000;

            .image-gallery-swipe {
                height: calc(100vh - 200px);
                background-color: #000;
                border: none;

                .image-gallery-slides {
                    height: 100%;

                    .image-gallery-slide {
                        height: 100%;

                        .image-gallery-image {
                            height: 100%;
                            // max-height: calc(100vh - 290px);
                            // min-height: calc(100vh - 290px);
                            object-fit: contain;

                            @include media-breakpoint-down(lg) {
                                max-height: calc(100vh - 180px);
                                min-height: calc(100vh - 180px);
                            }

                            @include media-breakpoint-down(md) {
                                max-height: calc(100vh - 185px);
                                min-height: calc(100vh - 185px);
                            }

                            @include media-breakpoint-down(sm) {
                                max-height: calc(100vh - 145px);
                                min-height: calc(100vh - 145px);
                            }
                        }
                    }
                }
            }
        }

        .image-gallery-thumbnail {
            max-height: 210px !important;
            width: 12.55% !important;

            @include media-breakpoint-down(md) {
                max-height: 140px !important;
            }

            @include media-breakpoint-down(sm) {
                max-height: 65px !important;
            }
        }
    }
}

.image-gallery-icon {
    &:hover {
        color: $base-color;
    }
}

.image-gallery-svg {
    height: 70px !important;
    width: 60px;
}

.image-gallery-thumbnail {
    width: 19% !important;

    &:focus,
    &:hover,
    &.active {
        border: 3px solid $base-color !important;
        transition: all 0.2s ease-in-out;
    }

    &.active {
        .image-gallery-thumbnail-inner {
            border: 2px solid $white;
        }
    }

    @include media-breakpoint-down(lg) {
        width: 19% !important;
    }
}

.image-gallery-thumbnails-wrapper {
    .image-gallery-thumbnails {
        $thumbnails-height: 145px;
        position: relative;
        min-height: $thumbnails-height;
        max-height: $thumbnails-height;
        height: 100%;
        display: flex;

        // &::before{
        //     content: "";
        //     position: absolute;
        //     top: 5px;
        //     left: 0;
        //     height: 100px;
        //     width: 60px;
        //     background: rgb(255,255,255);
        //     background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.45143995098039214) 50%, rgba(255,255,255,0) 100%);
        //     z-index: 100;
        // }

        // &::after{
        //     content: "";
        //     position: absolute;
        //     top: 5px;
        //     right: 0;
        //     height: 100px;
        //     width: 60px;
        //     background: rgb(255,255,255);
        //     background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.45143995098039214) 50%, rgba(255,255,255,1) 100%);
        //     z-index: 100;
        // }

        .image-gallery-thumbnails-container {
            .image-gallery-thumbnail {
                height: 100%;
                max-height: 100px;

                .image-gallery-thumbnail-inner {
                    height: 100%;

                    .image-gallery-thumbnail-image {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg) {
            min-height: 100px;
            max-height: 100px;
        }

        @include media-breakpoint-down(md) {
            min-height: 140px;
            max-height: 140px;
        }

        @include media-breakpoint-down(sm) {
            min-height: 70px;
            max-height: 70px;
        }
    }
}

.information-container {
    margin: 0.5rem 0 1.5rem 0;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: $bg-color-first;
    border-radius: 0.5em;
    box-shadow: 0 0 0 3px $bg-color-first;
    transition: all 0.2s ease-in-out;

    &:hover {
        box-shadow: 0 0 0 3px #dbe1eb;
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }
}

.product-info-block {
    margin: 0.675rem 0;
    display: flex;
    cursor: default;

    img {
        margin: auto 0.5rem auto 0;
        width: 1.5rem;
    }

    p {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 600;
        text-transform: none;
        transition: color 0.2s ease-in;
        line-height: 1.4em !important;

        span {
            margin-right: 0.5rem;
            flex: 0 0 auto;
            font-size: 0.875rem;
            font-family: "Oswald", sans-serif;
            font-weight: 400;
            color: $base-color;
            text-transform: uppercase;
        }
    }

    &:hover {
        p {
            color: $secondary-color;
        }
    }
}