.product-price {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  h5 {
    margin: 0 1rem 0 0;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  span {
    font-size: 2rem;
    font-weight: 600;
  }
}
