@import "../../variables";
.nav {
    display: flex;
    position: fixed;
    width: 100%;

    .nav-list {
        padding: 1rem 0;
        width: 100%;
        ul {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            li {
                list-style: none;
                margin: 0 15px;
                &:first-child {
                    margin-left: 0;
                    margin-right: auto;
                }
                &:last-child {
                    display: none;
                }
                a {
                    text-decoration: none;
                    color: $base-color;

                    &:hover {
                        color: $secondary-color;
                    }
                }

                @include media-breakpoint-down(md) {
                    &:last-child {
                        display: block;
                    }
                }
                @include media-breakpoint-down(md) {
                    &:first-child {
                        display: block !important;
                    }
                    &:not(:last-child) {
                        display: none;
                    }
                }
            }
            .real-estates {
                display: flex;
                align-items: center;

                .image-wrapper {
                    margin: 0 0.5rem 0.25rem 0;
                    width: 30px;
                }
            }
            .active {
                font-weight: 500;
                color: $secondary-color;
                text-decoration: none !important;
            }
        }

        @include media-breakpoint-down(md) {
            padding: 1rem 0;
        }
    }
    .image-wrapper {
        width: 120px;
        @include media-breakpoint-down(md) {
            width: 90px;
        }
    }
    &.scrolled {
        top: 0;
        background-color: rgba($color: $white, $alpha: 0.75);
        backdrop-filter: blur(5px);
        // border-bottom: 1px solid #ddd;
        box-shadow: 0 0 50px 0 rgba($color: #000000, $alpha: 0.1);
        // transition: all 0.2s ease-in;
        z-index: 101;
    }

    &.hidden {
        animation: hide-navbar 1s forwards;
    }
    &.show {
        animation: show-navbar 1s forwards;
    }

    .menu-icon {
        margin-left: auto;
        // padding: 20px 0;
        display: flex;
        position: relative;
        top: 0;
        user-select: none;
        cursor: pointer;

        .navicon {
            background-color: $base-color !important;
            &::before,
            &::after {
                background-color: $base-color !important;
            }
        }
        &:hover {
            .navicon {
                background-color: red;
                &::before,
                &::after {
                    background-color: red;
                }
            }
        }
        .navicon {
            background-color: $white;
            display: block;
            height: 4px;
            border-radius: $radius;
            -webkit-border-radius: $radius;
            position: relative;
            transition: background-color 0.2s ease-in;
            width: 30px;
            &::before,
            &::after {
                background-color: $white;
                border-radius: $radius;
                -webkit-border-radius: $radius;
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                transition: all 0.2s ease-in;
                width: 100%;
                cursor: pointer;
            }
            &::before {
                top: 10px;
            }
            &::after {
                top: -10px;
            }
        }

        &.checked {
            .navicon {
                background: transparent;
                &::before {
                    transform: rotate(-45deg);
                }
                &::after {
                    transform: rotate(45deg);
                }
            }
            &:not(.steps) {
                .navicon {
                    &:before,
                    &:after {
                        top: 0;
                    }
                }
            }
        }
    }
    .menu-btn {
        display: none;
        &:checked ~ .nav-list {
            max-height: 100%;
        }
        &:checked ~ .nav-list.close {
            max-height: 0;
        }
    }

    &.nav-mobile {
        $mobile-width: 275px;
        $after-width: 992px;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0%;
        right: -$mobile-width;
        width: $mobile-width;
        height: 100%;
        background-color: $white;
        z-index: 101;
        transition: right 0.3s ease-in;

        .top-nav {
            background-color: #3c2656;

            .top-links {
                .top-nav-item {
                    color: $white;
                }
            }
        }
        .nav-bar-wrapper {
            overflow: auto;
            height: 100%;
        }
        .after {
            position: absolute;
            top: 0;
            left: 100%;
            bottom: 0;
            width: $after-width;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.4);
            z-index: -1;
            opacity: 0;
        }
        &.selected {
            right: 0;
            .after {
                left: -$after-width;
                animation: mobileNavbar 0.3s forwards;
                animation-delay: 0.2s;
            }
        }
        .mobile-header {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            position: sticky;
            top: 0;
            z-index: 102;
            background-color: $white;
            .logo {
                img {
                    max-width: 160px;
                    height: auto;
                }
            }
            .button {
                display: flex;
                width: 35px;
                height: 35px;
                min-width: 35px;
                min-height: 35px;
                padding: 10px;
                margin-right: 0;
                border-radius: 30px;
                background: $base-color;
                img {
                    margin: 0;
                    width: 16px;
                    transition: all 0.2s ease-in-out;
                }
                .long-arrow-right {
                    display: none;
                }

                &:hover {
                    img {
                        transform: rotate(90deg) scale(0.8);
                    }
                }
            }
        }
        .nav-bar {
            padding: 0;
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            .top-nav {
                padding: 15px 15px 5px 15px;
                .top-links {
                    margin: 0 auto;
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .top-nav-item {
                        margin-bottom: 10px;
                        border-radius: $radius;
                        -webkit-border-radius: $radius;
                        padding: 10px 20px;
                        display: flex;
                        justify-content: space-between;

                        &.selected {
                            background-color: #210e37;
                        }
                    }
                }
            }
            .nav-list {
                display: flex;
                flex-direction: column;
                .nav-item,
                .nav-item-link {
                    padding: 15px 25px;
                    margin-right: 0;
                    &:before,
                    &:after {
                        display: none;
                    }

                    &.selected {
                        background-color: red;
                        color: $white;
                    }
                }
                ul {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    li {
                        display: block !important;
                        margin: 0 auto 0 15px;
                        padding: 0.5rem 0;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    &.top {
        overflow: hidden;
    }
    .nav-bar {
        .top-nav {
            .top-links {
                display: none;
            }
        }
    }
    .language {
        display: none;
    }
    &.page-nav {
        display: none;
    }
}
@include media-breakpoint-down(sm) {
    .nav-bar {
        padding: 15px;
    }
    .top-nav {
        .logo {
            img {
                max-width: 130px;
            }
        }
    }
    .connection-container {
        .connection-button {
            margin: 0 15px 0 5px;
            padding: 8px 20px 8px 15px;
            img {
                display: none;
            }
            span {
                display: none;
            }
        }

        .connection-status {
            font-size: 18px;
        }
    }
}
@include media-breakpoint-up(xl) {
    .nav-list {
        max-height: none;
    }
    .menu-icon {
        display: none;
    }
    &.nav-mobile {
        display: none;
    }
}

@keyframes mobileNavbar {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes hide-navbar {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-90px);
    }
}

@keyframes show-navbar {
    0% {
        transform: translateY(-90px);
    }
    100% {
        transform: translateY(0);
    }
}
