@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "./variables";
@import "animate.css/animate.min.css";

// html,
// body {
//   // overflow-x: hidden;
// }

body {
  margin: 0;
  // font-family: "Montserrat Alternates", sans-serif !important;
  font-family: 'Overpass', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-family: "Oswald", sans-serif !important;
  color: $base-color;
}

p {
  margin-bottom: 0 !important;
  // font-family: "Open Sans", sans-serif;
  // font-family: "Montserrat Alternates", sans-serif;
  font-family: 'Overpass', sans-serif !important;
  // font-family: 'Nunito Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.6em;
  font-weight: 300;
}

strong {
  font-weight: 600 !important;
}

a {
  text-decoration: none !important;
}

nav {
  z-index: 99;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
