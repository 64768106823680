@import "../../variables";
.error-page-container {
    height: 100vh;
    min-height: $main-height;
    background-image: url(../../assets/images/main-bg.jpg);
    background-size: cover;
    background-position: bottom;
    background-attachment: initial;

    @include media-breakpoint-down(xl) {
        min-height: $main-height - 50px;
    }

    .main-title-container {
        margin-top: 10rem;
        @include media-breakpoint-down(xl) {
            margin-top: 8rem;
        }
    }

    .error-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        h1 {
            position: absolute;
            font-size: 15rem;
            color: $base-color;
            opacity: 0.1;
        }
        .content {
            font-size: 1rem;
            color: $secondary-color;
            padding: 12rem 0 10rem 0;
        }
    }
}
