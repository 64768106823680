@import "../../variables";

.dashboard-main {
    overflow: hidden;
}

main {
    position: relative;
    min-height: 1080px;
    @include media-breakpoint-down(lg) {
        min-height: 900px;
    }

    @include media-breakpoint-down(md) {
        min-height: 800px;
    }

    &::after {
        content: "";
        width: 100%;
        height: 152px;
        position: absolute;
        top: calc(100% - 152px);
        left: 0;
        background-image: url("../../assets/images/header-mask.png");
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
    }

    .dashboard-background {
        position: fixed;
        top: 0;
        width: 100%;
        overflow: hidden;
        z-index: -1;
        animation: firstLoad 1s linear forwards;
        animation-delay: 0.5s;
        opacity: 0;

        $bottomPosition: 200px;

        .main-image {
            position: relative;
            left: 0;
            bottom: $bottomPosition;
            opacity: 1;
            max-width: 1221px;
            min-width: 1221px;
        }
        .hover-image {
            position: absolute;
            left: 0;
            bottom: $bottomPosition;
            animation: fadeOut 0.5s ease-out forwards;
            opacity: 0;
            max-width: 1221px;
            min-width: 1221px;
        }

        @include media-breakpoint-down(lg) {
            .main-image,
            .hover-image {
                left: -15rem;
            }
        }

        @include media-breakpoint-down(sm) {
            .main-image,
            .hover-image {
                left: -25rem;
            }
        }

        img {
            transform: scale(100%);
        }
    }

    @include media-breakpoint-down(sm) {
        .main-container {
            .main-title-container {
                h1 {
                    font-size: 1.05rem;
                }
                p {
                    margin: 0 1rem;
                    font-size: 0.75rem;
                }
            }
        }
    }

    .show {
        .hover-image {
            animation: fadeIn 0.3s ease-in forwards;
            opacity: 1;
        }
    }

    .main-elements {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        align-items: center;
        width: 100%;
        .element {
            margin: 0.5rem 0 1.5rem 0;
            position: relative;
            width: 100%;
            height: 100px;
            overflow: hidden;
            &::before {
                content: "";
                position: absolute;
                left: 50%;
                width: 2px;
                height: 100px;
                border-radius: $radius;
                background: linear-gradient(180deg, $base-color 0%, $secondary-color 100%);
            }
        }
    }

    .animate {
        .main-elements {
            .element {
                &::before {
                    opacity: 0;
                    animation-delay: 1.5s !important;
                    animation: elementShow 0.5s forwards;
                }
            }
            .counters-container {
                opacity: 0;
                animation-delay: 2s !important;
                animation: buttonShow 0.5s forwards;
                .button {
                    opacity: 0;
                    animation-delay: 2.5s !important;
                    animation: buttonShow 0.5s forwards;
                }
            }
            .contact-container {
                opacity: 0;
                animation-delay: 3s !important;
                animation: buttonShow 0.5s forwards;
            }
        }
        .dashboard-images-wrapper {
            opacity: 0;
            animation-delay: 2s !important;
            animation: fadeIn 2s forwards;

            .first {
                opacity: 0;
                animation-delay: 3s !important;
                animation: fadeInLeft 1s forwards;
            }

            .second {
                opacity: 0;
                animation-delay: 4s !important;
                animation: fadeInLeft 1s forwards;
            }

            .third {
                opacity: 0;
                animation-delay: 3.5s !important;
                animation: fadeInLeft 1s forwards;
            }
        }
    }

    .dashboard-box {
        padding: 4rem 0 1rem 0;
        width: 60%;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 300px;
        }
    }

    .counters-container {
        margin-bottom: 3rem;
        padding: 0.75rem 0 0.75rem 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        border-radius: $radius * 2;
        box-shadow: 0 5px 25px 0 rgba($color: $base-color, $alpha: 0.15);
        z-index: 1;

        .counters-wrapper {
            display: flex;
            .counter-container {
                margin: 0 1.5rem;
            }
        }

        .counter-button {
            margin: 0 1.5rem;
        }

        @include media-breakpoint-down(sm) {
            padding: 0.75rem 1.5rem;
            flex-direction: column;
            .counters-wrapper {
                margin-bottom: 1rem;
                .counter-container {
                    margin: 0 1rem;
                }
            }
            .counter-button {
                margin: 0 0 0.5rem 0;
            }
        }
    }

    .contact-container {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        z-index: 1;

        .contact-name {
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
                font-weight: 900;
            }
            a {
                color: $secondary-color;
            }
        }
        .social-icons {
            margin-top: 1rem;
            display: flex;
            .social-icon {
                img {
                    margin: 0 0.5rem;
                }
                p {
                    display: none;
                }
            }
        }
    }
}

.dashboard-container {
    padding-bottom: 5rem;
    background-color: $white;
    background-image: url(../../assets/images/bottom-bg.jpg);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: initial;
    border-bottom: 1px solid #ddd;
}

.stop {
    overflow: hidden !important;
}
