@import "../../variables";
footer {
    padding-top: 3rem;
    padding-bottom: 1rem;
    background-color: $base-color;
    color: $white;

    .footer-navbar-container {
        .logo-container {
            display: flex;
            justify-content: flex-end;
            width: 450px;
            .image-wrapper {
                margin-right: 1rem;
                width: 100%;
                max-width: 150px;
            }
            .logo-text {
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 0.75rem;
                letter-spacing: 0.5em;
                line-height: 2em;
                text-align: center;
                span {
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        left: -114px;
                        width: 228px;
                        height: 1px;
                        background-color: $white;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                margin: 0 auto 2rem auto;
                flex-direction: column;
                width: 100%;
                .image-wrapper {
                    margin: 0 auto 0.5rem auto;
                    max-width: 120px;
                }
                .logo-text {
                    font-size: 0.65rem;
                }
            }
        }
        a {
            color: $white;
            text-decoration: none;
        }
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            width: 100%;
            li {
                margin-left: 2rem;
                list-style: none;
                letter-spacing: 0.2em;
                font-size: 0.875rem;
            }
            @include media-breakpoint-down(md) {
                justify-content: center;
            }

            @include media-breakpoint-down(sm) {
                //    flex-direction: column;
                //    justify-content: center;
                //    align-items: center;
                li {
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;

                    font-size: 0.75rem;
                }
            }
        }
    }
    .copyright-container {
        margin-top: 3rem;
        text-align: center;
        font-size: 0.875rem;
        .copy {
            p {
                // font-family: "Oswald", sans-serif;
                font-size: 0.75rem;
                font-weight: 300;
                letter-spacing: 0.2em;
            }
        }
    }
}
