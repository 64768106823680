@import "../../variables";

.new-offers-container {
  margin-top: 5rem;

  .more-new-offers{
    .title-container {
      margin-top: 4rem;
      h2 {
        font-size: 1.5rem;
      }
      img {
        width: 90px;
      }
    }
    .product-list {
      .product-card {
        &:first-child {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 1rem;

    .more-new-offers{
      .title-container {
        margin-top: 1rem;
      }
    }
  }
}
