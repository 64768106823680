@import "../../variables";
.services-main {
    min-height: $main-height;
    background-image: url(../../assets/images/main-bg.jpg);
    background-size: cover;
    background-position: bottom;
    background-attachment: initial;

    @include media-breakpoint-down(xl) {
        min-height: $main-height - 50px;
    }

    .main-title-container {
        margin-top: 10rem;

        @include media-breakpoint-down(xl) {
            margin-top: 8rem;
        }
    }
}

.services-page-container {
    padding-bottom: 5rem;
    min-height: 980px;

    background-image: url(../../assets/images/services-bg.jpg);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: initial;

    .explore-tabs-container {
        .accent-container {
            margin-top: 0.65rem;
            padding: 1.5rem;
            animation: fadeIn 0.3s ease-in-out forwards;

            @include media-breakpoint-down(md) {
                padding: 1.5rem 0.875rem;
            }
            h2 {
                margin-bottom: 1rem;
                font-weight: 200;
                span {
                    font-weight: 500;
                }
            }
            .button {
                margin-top: 2rem;
            }
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        .nav {
            position: relative;

            &.nav-pills {
                padding: 1rem;
                display: flex;
                background-color: $bg-color-first;
                border-radius: $radius;
                transition: all 0.3s ease-in-out;

                &:hover {
                    box-shadow: 0 30px 25px -20px rgba($color: $base-color, $alpha: 0.3);
                }

                @include media-breakpoint-down(lg) {
                    margin-left: -15px;
                    margin-top: 35px;
                    margin-right: 0;
                }

                @include media-breakpoint-down(md) {
                    margin-left: -15px;
                    margin-top: 0;
                    margin-right: 0;

                    .nav-link {
                        padding: 0.5rem 1rem;
                    }
                }

                @include media-breakpoint-only(xs) {
                    margin-top: 0;
                    margin-left: 0;
                    margin-bottom: 25px;
                    align-items: flex-start;
                }
                .nav-link {
                    margin: 0.5rem 0;
                    padding: 1rem;
                    display: flex;
                    align-items: baseline;
                    position: relative;
                    color: $dark;
                    font-weight: 500;
                    border: 1px solid transparent;
                    border-radius: $radius;
                    outline: none;
                    box-shadow: none;
                    transition: all 0.2s ease-in;
                    cursor: default;
                    font-size: 1.125rem;

                    span {
                        margin-right: 0.5rem;
                        font-family: "Oswald", sans-serif;
                        color: $base-color;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &.active {
                        background-color: $white;
                        color: $base-color;
                        border: 1px solid $base-color;
                    }

                    &:hover:not(.active) {
                        background: $white;
                        cursor: pointer;
                        color: $base-color;
                        border: 1px solid $base-color;
                    }

                    @include media-breakpoint-down(md) {
                        padding: 0.5rem 1rem;
                    }
                }
            }

            .tab-content {
                @include media-breakpoint-only(xs) {
                    margin-top: 25px;
                }
            }
        }
        .box-context {
            .tab-content {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                    background-color: $white;
                    border-radius: $radius;
                    opacity: 0.7;
                    transition: opacity 0.3s ease-in-out;
                    z-index: -1;
                }

                &:hover {
                    &::after {
                        opacity: 0.9;
                    }
                }
            }
            .tab-pane {
                position: relative;
                min-height: 245px;
                border-radius: $radius;
                .image-wrapper {
                    position: absolute;
                    top: -30px;
                    right: -30px;
                    width: 100px;
                }
            }
        }
    }
}
